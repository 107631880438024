import React, { useEffect, useState, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Page, CatalogTable, ActionsGroup, SelectFine } from '../components';
import * as offerApi from '../api/offer-api';
import { getCookie } from '../utils/cookies';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

const CatalogPage = ({ classes }) => {
  const { t } = useTranslation();
  const [isLoggedIn] = useState(!!localStorage.getItem('access_token'));
  const [isCustomer] = useState(localStorage.getItem('is_customer') === 'true');

  const [offerList, setOfferList] = useState([]);
  const [offer, setOffer] = useState([]);
  const [offerData, setOfferData] = useState([]);

  const [brandList, setBrandList] = useState([]);
  const [brand, setBrand] = useState([]);

  const initialized = useRef(false);

  async function getOfferList() {
    const result = await offerApi.offers();

    setOfferList(
      result.map((row) => ({
        title: row['text'],
        value: row['logo'],
      }))
    );

    if (result !== null && result.length !== 0) {
      setOffer(result[0].logo);
    }
  }

  async function getBrandList() {
    const result = await offerApi.brands();

    setBrandList(
      result.map((row) => ({
        title: row['text'],
        value: row['logo'],
      }))
    );

    if (result !== null && result.length !== 0) setBrand(result[0].logo);
  }

  async function fetchOfferData(offer, brand) {
    setOfferData([]);

    const result = await offerApi.resources(offer, brand);
    setOfferData(result);
  }

  function handleOfferChange(value) {
    setOffer(value);
  }

  function handleBrandChange(value) {
    setBrand(value);
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      getOfferList().then();

      getBrandList().then();
    }
  }, []);

  useEffect(() => {
    if (offer && brand) fetchOfferData(offer, brand).then();
  }, [offer, brand]);

  return (
    <Page>
      <ActionsGroup className={classes.searchContainer}>
        <SelectFine
          items={offerList}
          value={offer}
          label={t('offer')}
          onChange={handleOfferChange}
        />

        <SelectFine
          items={brandList}
          value={brand}
          label={t('brand')}
          onChange={handleBrandChange}
        />
        <div></div>
        <div></div>
        <div></div>
      </ActionsGroup>

      {!isCustomer ? (
        <div className={classes.warnPanel}>
          <Typography className={classes.warnLabel}>
            {isLoggedIn ? (
              <Link to="/bank">{t('offers_complete_registration')}</Link>
            ) : (
              t('offers_log_in')
            )}
          </Typography>
        </div>
      ) : null}

      <div className={classes.tableContainer}>
        <CatalogTable data={offerData} isLoggedIn={isLoggedIn} />
      </div>
    </Page>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.catalogPage;

  const searchContainer = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.unit,
    padding: `0 ${theme.spacing.unit}px`,
  };

  return {
    actionsGroup: {
      justifyContent: 'flex-end',
    },
    searchContainer: {
      ...searchContainer,
      justifyContent: 'space-between',
    },
    showButton: {
      marginRight: theme.spacing.unit,
    },
    tableContainer: theme.tableContainer,

    warnPanel: {
      borderRadius: '7px',
      backgroundColor: colors.table.selectedRow,
      textAlign: 'center',
      minHeight: 30,
      padding: '5px',
      marginBottom: theme.spacing.unit,
    },
    warnLabel: {
      textTransform: 'uppercase',
      color: 'black',
    },
  };
};

export default withStyles(styles)(CatalogPage);
